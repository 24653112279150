import React, {FC} from 'react';

import featureSWAP from '../Resources/PIC.png';
import featureUnlimited from '../Resources/PIC-1.png';
import featureHD from '../Resources/PIC-2.png';

import * as amplitude from "@amplitude/analytics-browser";
import {buildAppUrl} from "../Helpers/BuildAppUrl";

interface FeatureProps {
    title: string,
    subtitle: string,
    image: string
}

const FeatureCard: FC<FeatureProps> = (props) => {
    return (
        <div className="flex flex-col gap-4 bg-neutral-900 rounded-3xl border-solid border-neutral-700 p-2">
            <div className="font-extrabold text-transparent text-2xl bg-clip-text bg-gradient-to-r from-pink-400 to-purple-500 --webkit-font-['Unbounded'] leading-10">{props.title}</div>
            <div className="text-center text-zinc-100 text-xl font-bold --webkit-font-['Onest'] leading-7">{props.subtitle}</div>
            <img className="pt-4" src={props.image}/>
        </div>
    );
};

const FeaturesBlock = () => {
    return (
        <div className="flex flex-col bg-neutral-900 justify-start items-start gap-4 w-full px-8 xl:px-48 py-8">
            <div className="justify-start items-center pb-8">
                <h2 className="text-zinc-100 text-5xl font-bold --webkit-font-['Unbounded'] leading-10">Features: Free Deepfake Maker Online</h2>
            </div>
            <div className="flex flex-col lg:flex-row gap-2 justify-between w-full place-items-center">
                <FeatureCard title="SWAP" subtitle="any face on any video" image={featureSWAP}/>
                <FeatureCard title="UNLIMITED" subtitle="video uploads" image={featureUnlimited}/>
                <FeatureCard title="HIGH RES" subtitle="face swap" image={featureHD}/>
            </div>
            <div className="w-full text-left p-6 rounded-3xl border-solid border-neutral-700 flex-col justify-start items-start gap-8 inline-flex">
                <p className="self-stretch text-zinc-100 text-2xl font-medium --webkit-font-['Onest'] leading-loose">Use our free deepfake app to create stunning deepfake videos right from your laptop or smartphone.</p>
                <div className="self-stretch h-14 flex-col justify-center items-center md:items-end gap-2.5 flex">
                    <div
                        className="transition ease-in-out delay-75 w-60 h-14 p-2 bg-pink-500 rounded-2xl justify-center items-center gap-2.5 inline-flex hover:cursor-pointer hover:-translate-y-1 hover:scale-105 duration-100"
                        onClick={() => window.open(buildAppUrl())}
                    >
                        <div className="text-center text-zinc-100 text-xl font-medium --webkit-font-['Onest'] leading-normal">Launch App</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FeaturesBlock;