import React from 'react';

import tryApple from '../Resources/Badges.png';
import tryGoogle from '../Resources/Badges-1.png';
import tryWeb from '../Resources/Badges-2.png';
import tryTelegram from '../Resources/badge-tg.png'

import * as amplitude from '@amplitude/analytics-browser';
import {buildAppUrl, buildTgURL} from "../Helpers/BuildAppUrl";

const TryBlock = () => {
    const className =
        "w-40 cursor-pointer transition ease-in-out delay-75 hover:-translate-y-1 hover:scale-105 duration-100";

    return (
        <div className="flex flex-col bg-neutral-900 justify-start items-start gap-4 w-full px-8 xl:px-48 py-8">
            <div className="w-full text-left p-6 rounded-3xl border-solid border-neutral-700 flex-col justify-start items-start gap-8 inline-flex">
                <p className="self-stretch text-zinc-100 text-2xl font-medium --webkit-font-['Onest'] leading-loose">Don't wait to create your own deepfakes! With DeepSwap, our powerful deepfake AI technology, crafting realistic deepfakes is just a few clicks away. Dive into the world of deep swap and experience the capability that our deepfakes web platform provides. Start creating, modifying, and sharing your deepfakes today. Unleash your imagination with the leading deepfake AI in the industry - DeepSwap. Click here to get started!</p>
                <div className="self-stretch flex-col justify-center items-center md:items-end gap-2.5 flex">
                    <div className="p-2 rounded-3xl justify-center items-center gap-2.5 flex flex-col md:flex-row">
                        <img
                            className={className}
                            src={tryWeb}
                            onClick={() => window.open(buildAppUrl())}
                        />
                        <img
                            className={className}
                            src={tryApple}
                            onClick={() => window.open("https://cutt.ly/ieSfolSi")}
                        />
                        <img
                            className={className}
                            src={tryTelegram}
                            onClick={() => window.open(buildTgURL())}
                        />
                        {/*<img*/}
                        {/*    className={className}*/}
                        {/*    src={tryGoogle}*/}
                        {/*    onClick={() => window.open("https://play.google.com/store/apps/details?id=com.amponsah.ai_face_swap")}*/}
                        {/*/>*/}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TryBlock;