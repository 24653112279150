import React, {FC, MutableRefObject, useRef} from "react";
import {Layout, Space} from "antd";
import HeaderPanel from "../Components/HeaderPanel";
import HeroBlock from "../Components/HeroBlock";
import FeaturesBlock from "../Components/FeaturesBlock";
import StepsBlock from "../Components/StepsBlock";
import FeedbackBlock from "../Components/FeedbackBlock";
import TryBlock from "../Components/TryBlock";
import FooterPanel from "../Components/FooterPanel";
import {buildAppUrl} from "../Helpers/BuildAppUrl";
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';
import {ExamplePreview} from "../Components/ExamplePreview";

const { Header, Footer, Sider, Content } = Layout;

const ExampleDuaLipaPage: FC = () => {
    return (
        <div className="bg-neutral-900">
            <Space direction="vertical" style={{ width: '100%' }}>
                <Layout>
                    <a
                        href={"https://deepfakex.ai"}
                        className="left-[24px] top-[12px] absolute invisible md:visible font-bold text-transparent text-2xl bg-clip-text bg-gradient-to-r from-pink-400 to-purple-500 --webkit-font-['Unbounded'] leading-10"
                    >
                        DeepfakeX
                    </a>
                    <Content>
                        <article>
                            <div
                                className="flex flex-col bg-neutral-900 justify-start items-start gap-4 px-8 xl:px-48 py-8 xl:py-16">
                                <h1 className="font-extrabold text-zinc-100 text-xl lg:text-3xl bg-clip-text --webkit-font-['Unbounded'] leading-10">
                                    Dua Lipa Deepfake Example</h1>
                                <time
                                    dateTime="2024-10-30T13:21:09"
                                    className="text-center text-zinc-400 text-sm font-medium --webkit-font-['Onest'] leading-normal"
                                >
                                    October 30th, 2024, 13:21
                                </time>
                                <div
                                    className="transition ease-in-out delay-75 px-10 py-3 bg-pink-500 rounded-2xl hover:cursor-pointer hover:-translate-y-1 hover:scale-105 duration-100"
                                    onClick={() => window.open(buildAppUrl())}
                                >
                                    <div
                                        className="text-center text-zinc-100 text-sm font-bold --webkit-font-['Onest'] leading-normal"
                                    >
                                        Create Deepfake Video
                                    </div>
                                </div>
                                <div className="w-7/12">
                                    <LiteYouTubeEmbed
                                        id="q6Wa2drBTaY"
                                        title="Dua Lipa Deepfake Example"
                                    />
                                </div>
                                <p
                                    className="text-left text-zinc-100 text-lg font-medium --webkit-font-['Onest'] leading-normal"
                                >
                                    Dua Lipa, the global pop sensation known for her chart-topping hits and distinctive
                                    voice, has recently become a fascinating subject within the deepfake community. An
                                    intriguing example of this involves a Dua Lipa deepfake where her face and voice are
                                    seamlessly integrated into an ASMR video. This Dua Lipa deep fake effectively
                                    immerses viewers in a calming, sensory experience, showcasing the pop star as an
                                    ASMR artist delivering soothing whispers and gentle sounds.
                                    <br/><br/>
                                    The technology behind this transformation uses sophisticated algorithms to replicate
                                    Lipa’s facial expressions and vocal nuances with remarkable accuracy, blurring the
                                    lines between reality and digital creation. Such content captivates both fans and
                                    ASMR enthusiasts, demonstrating the versatile applications of deepfake technology in
                                    offering novel forms of entertainment. However, it also sparks necessary debates
                                    about consent, authenticity, and the ethical implications of using a celebrity's
                                    likeness in this manner without proper authorization.
                                    <br/><br/>
                                    For the Face Swap and Deepfake industry, these examples serve as a testament to the
                                    engaging possibilities brought by advanced AI, while also highlighting the crucial
                                    need for responsible use and ethical standards. By understanding the dynamics of
                                    these technologies, creators can explore new horizons of digital content, ensuring
                                    they do so with integrity and respect for the individuals involved. The Dua Lipa
                                    deepfake ASMR video is a prime illustration of how innovation can craft unique,
                                    immersive experiences while pushing the boundaries of digital artistry.
                                </p>
                            </div>
                        </article>
                    </Content>
                    <FooterPanel/>
                </Layout>
            </Space>
        </div>
    );
};

export default ExampleDuaLipaPage;