import React, {FC} from "react";
import stars from "../Resources/Rating.png";
import LiteYouTubeEmbed from "react-lite-youtube-embed";

interface ExamplePreviewProps {
    title: string,
    videoId: string
}

export const ExamplePreview: FC<ExamplePreviewProps> = (props) => {
    return (
        <div className="text-center self-stretch p-8 bg-neutral-800 rounded-3xl justify-center items-start gap-6 flex max-w-screen-sm h-fit">
            <div className="grow basis-0 flex-col justify-start items-center gap-4 inline-flex max-w-screen-sm">
                <div className="self-stretch text-stone-300 text-xs lg:text-xl font-bold --webkit-font-['Onest'] leading-normal">{props.title}</div>
                <div className="w-11/12">
                    <LiteYouTubeEmbed
                        id={props.videoId}
                        title={props.title}
                    />
                </div>
            </div>
        </div>
    );
};