import React, {FC, MutableRefObject} from 'react';

import * as amplitude from "@amplitude/analytics-browser";
import featureSWAP from "../Resources/PIC.png";
import featureUnlimited from "../Resources/PIC-1.png";
import featureHD from "../Resources/PIC-2.png";

import { Layout, Space } from 'antd';
import TryBlock from "../Components/TryBlock";
import {useRef} from 'react';
import stepTwo from "../Resources/IMG Area-1.png";
import stepOne from "../Resources/IMG Area.png";
import stepThree from "../Resources/IMG Area-2.png";
import stars from "../Resources/Rating.png";
import janeFace from "../Resources/Faces/JaneFace.png";
import annaFace from "../Resources/Faces/AnnaFace.jpeg";
import michaelFace from "../Resources/Faces/MichaelFace.png";
const { Header, Footer, Sider, Content } = Layout;

const LeftPart = () => {
    return (
        <div className="flex flex-col-reverse lg:flex-col justify-start items-start gap-8">
            <div className="self-stretch flex-col justify-start items-start gap-14 flex">
                <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                    <h1 className="font-extrabold text-transparent text-3xl bg-clip-text bg-gradient-to-r from-pink-400 to-purple-500 --webkit-font-['Unbounded'] leading-10">Face AI - Лучший Deepfake Бот</h1>
                    <p className="text-left text-zinc-100 text-2xl font-medium --webkit-font-['Onest']">Face AI - лучшее решение для создания высококачественных дипфейков. Используя нашего бота, Вы сможете создавать дипфейки так быстро, как никогда раньше.</p>
                </div>
            </div>
            <div className="self-stretch justify-center items-center gap-6 inline-flex">
                <div
                    className="w-full md:max-w-md transition ease-in-out delay-75 grow shrink basis-0 h-14 p-2 bg-gradient-to-r from-pink-500 via-purple-800 to-indigo-400 rounded-3xl justify-center items-center gap-2.5 flex hover:cursor-pointer hover:-translate-y-1 hover:scale-105 duration-100"
                    onClick={() => window.open("https://t.me/ai_face_deepfake_bot")}
                >
                    <div
                        className="text-center text-zinc-100 text-xl font-medium --webkit-font-['Onest'] leading-normal"
                    >
                        Открыть Бот
                    </div>
                </div>
            </div>
        </div>
    );
};

const HeroBlock = () => {
    return (
        <div className="flex flex-col-reverse lg:flex-row bg-neutral-900 justify-start items-center gap-16 px-8 xl:px-48 py-8 xl:py-16">
            <LeftPart/>
            <video
                className="w-64 md:w-80 rounded-3xl hover:cursor-pointer border-solid border-neutral-600 border-2" src="https://deepfakex.ai/IMG_2104.mp4" autoPlay muted loop playsInline
                onClick={() => {
                    window.open("https://t.me/ai_face_deepfake_bot");
                }}
            />
        </div>
    );
};

interface FeatureProps {
    title: string,
    subtitle: string,
    image: string
}

const FeatureCard: FC<FeatureProps> = (props) => {
    return (
        <div className="flex flex-col gap-4 bg-neutral-900 rounded-3xl border-solid border-neutral-700 p-2">
            <div className="font-extrabold text-transparent text-2xl bg-clip-text bg-gradient-to-r from-pink-400 to-purple-500 --webkit-font-['Unbounded'] leading-10">{props.title}</div>
            <div className="text-center text-zinc-100 text-xl font-bold --webkit-font-['Onest'] leading-7">{props.subtitle}</div>
            <img className="pt-4" src={props.image}/>
        </div>
    );
};

const FeaturesBlock = () => {
    return (
        <div className="flex flex-col bg-neutral-900 justify-start items-start gap-4 w-full px-8 xl:px-48 py-8">
            <div className="justify-start items-center pb-8">
                <h2 className="text-zinc-100 text-5xl font-bold --webkit-font-['Unbounded'] leading-10">Уникальные особенности</h2>
            </div>
            <div className="flex flex-col lg:flex-row gap-2 justify-between w-full place-items-center">
                <FeatureCard title="МЕНЯЙ" subtitle="лицо на любом видео" image={featureSWAP}/>
                <FeatureCard title="НЕОГРАНИЧЕННО" subtitle="загрузок видео" image={featureUnlimited}/>
                <FeatureCard title="ВЫСОКОЕ КАЧЕСТВО" subtitle="результата" image={featureHD}/>
            </div>
            <div className="w-full text-left p-6 rounded-3xl flex-col justify-start items-start gap-8 inline-flex">
                <div className="self-stretch h-14 flex-col justify-center items-center gap-2.5 flex">
                    <div
                        className="transition ease-in-out delay-75 w-60 h-14 p-2 bg-gradient-to-r from-pink-500 via-purple-800 to-indigo-400 rounded-3xl justify-center items-center gap-2.5 inline-flex hover:cursor-pointer hover:-translate-y-1 hover:scale-105 duration-100"
                        onClick={() => window.open("https://t.me/ai_face_deepfake_bot")}
                    >
                        <div className="text-center text-zinc-100 text-xl font-medium --webkit-font-['Onest'] leading-normal">Запустить Бота</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

interface StepProps {
    title: string,
    subtitle: string,
    number: string,
    image: string
}

const StepCard: FC<StepProps> = (props) => {
    return (
        <div className="flex flex-col gap-4 bg-neutral-900 rounded-3xl border-solid border-spacing-0.5 border-pink-500 p-4 text-left">
            <img className="h-32" src={props.image}/>
            <div className="font-extrabold text-transparent text-5xl bg-clip-text bg-gradient-to-r from-pink-400 to-purple-500 --webkit-font-['Unbounded'] leading-10">{props.number}</div>
            <div className="text-zinc-100 text-xl font-bold --webkit-font-['Unbounded'] leading-10">{props.title}</div>
            <div className="text-zinc-500 text-lg font-normal --webkit-font-['Onest'] leading-normal">{props.subtitle}</div>
        </div>
    );
};

const StepsBlock = () => {
    return (
        <div
            className="flex flex-col bg-neutral-900 justify-start items-start gap-4 w-full px-8 xl:px-48 py-8 text-left">
            <div className="justify-start items-center">
                <h2 className="text-zinc-100 text-5xl font-bold --webkit-font-['Unbounded'] leading-10">3 простых
                    шага</h2>
            </div>
            <div className="flex flex-col lg:flex-row gap-2 justify-between w-full place-items-center">
                <StepCard number="1" title="Загрузи видео" subtitle="Выбирай любое видео до 2 минут" image={stepTwo}/>
                <StepCard number="2" title="Загрузи фото" subtitle="Изображение с целевым лицом" image={stepOne}/>
                <StepCard number="3" title="Получи результат!" subtitle="Наслаждайся полученным видео"
                          image={stepThree}/>
            </div>
            <div
                className="w-full text-left p-6 rounded-3xl flex-col justify-start items-start gap-8 inline-flex">
                <div className="self-stretch h-14 flex-col justify-center items-center gap-2.5 flex">
                    <div
                        className="transition ease-in-out delay-75 w-60 h-14 p-2 bg-gradient-to-r from-pink-500 via-purple-800 to-indigo-400 rounded-3xl justify-center items-center gap-2.5 inline-flex hover:cursor-pointer hover:-translate-y-1 hover:scale-105 duration-100"
                        onClick={() => window.open("https://t.me/ai_face_deepfake_bot")}
                    >
                        <div
                            className="text-center text-zinc-100 text-xl font-medium --webkit-font-['Onest'] leading-normal">
                            Запустить Бота
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

interface FeedbackProps {
    name: string,
    review: string,
    avatar: string
}

const FeedbackCard: FC<FeedbackProps> = (props) => {
    return (
        <div
            className="text-left self-stretch p-8 bg-neutral-800 rounded-3xl justify-center items-start gap-6 flex max-w-screen-sm h-fit">
            <img className="w-16 h-16 rounded-full" src={props.avatar}/>
            <div className="grow basis-0 flex-col justify-start items-start gap-2 inline-flex max-w-screen-sm">
                <div
                    className="self-stretch text-stone-300 text-xl font-bold --webkit-font-['Onest'] leading-normal">{props.name}</div>
                <img className="w-32" src={stars}/>
                <div
                    className="self-stretch text-zinc-100 text-lg font-normal --webkit-font-['Onest'] leading-normal">{props.review}</div>
            </div>
        </div>
    );
};

const FeedbackBlock = () => {
    return (
        <div className="flex flex-col bg-neutral-900 justify-start items-start gap-4 w-full px-8 xl:px-48 py-8">
            <div className="justify-start items-center">
                <div className="text-left text-zinc-100 text-5xl font-bold --webkit-font-['Unbounded'] leading-10">Что
                    говорят пользователи?
                </div>
            </div>
            <p className="text-left text-zinc-500 text-2xl font-medium --webkit-font-['Onest'] leading-loose">Более 1000
                пользователей уже высоко оценили наше решение.</p>
            <div className="self-center gap-6 flex flex-col lg:flex-row">
                <FeedbackCard
                    name={"Юлия П."}
                    review={"Мне сильно нравится. Работает быстро, ОЧЕНЬ быстро."}
                    avatar={janeFace}
                />
                <FeedbackCard
                    name={"Анна С."}
                    review={"Все очень понятно, при этом качество на высоте. ИИ очень хорошо распознает исходное и целевое лицо. Я сразу подключила подписку, хотя делаю это редко."}
                    avatar={annaFace}
                />
                <FeedbackCard
                    name={"Михаил Л."}
                    review={"Качество на высоте, точно стоит своих денег. Очень рад, что так же можно работать с GIF. "}
                    avatar={michaelFace}
                />
            </div>
            <div
                className="w-full text-left p-6 rounded-3xl flex-col justify-start items-start gap-8 inline-flex">
                <div className="self-stretch h-14 flex-col justify-center items-center gap-2.5 flex">
                    <div
                        className="transition ease-in-out delay-75 w-60 h-14 p-2 bg-gradient-to-r from-pink-500 via-purple-800 to-indigo-400 rounded-3xl justify-center items-center gap-2.5 inline-flex hover:cursor-pointer hover:-translate-y-1 hover:scale-105 duration-100"
                        onClick={() => window.open("https://t.me/ai_face_deepfake_bot")}
                    >
                        <div
                            className="text-center text-zinc-100 text-xl font-medium --webkit-font-['Onest'] leading-normal">Запустить
                            Бота
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export interface BlockProps {
    ref: React.MutableRefObject<any>
}

const TelegramPreland: FC = () => {

    return (
        <div className="bg-neutral-900">
            <Space direction="vertical" style={{width: '100%'}}>
                <Layout>
                    <Content>
                        <HeroBlock/>
                        <FeaturesBlock/>
                        <StepsBlock/>
                        <FeedbackBlock/>
                    </Content>
                </Layout>
            </Space>
        </div>
    );
};
export default TelegramPreland;