import React, {FC, MutableRefObject, useRef} from "react";
import {Layout, Space} from "antd";
import HeaderPanel from "../Components/HeaderPanel";
import HeroBlock from "../Components/HeroBlock";
import FeaturesBlock from "../Components/FeaturesBlock";
import StepsBlock from "../Components/StepsBlock";
import FeedbackBlock from "../Components/FeedbackBlock";
import TryBlock from "../Components/TryBlock";
import FooterPanel from "../Components/FooterPanel";
import {buildAppUrl} from "../Helpers/BuildAppUrl";
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';
import {ExamplePreview} from "../Components/ExamplePreview";

const { Header, Footer, Sider, Content } = Layout;

const ExampleBobbyBrownPage: FC = () => {
    return (
        <div className="bg-neutral-900">
            <Space direction="vertical" style={{ width: '100%' }}>
                <Layout>
                    <a
                        href={"https://deepfakex.ai"}
                        className="left-[24px] top-[12px] absolute invisible md:visible font-bold text-transparent text-2xl bg-clip-text bg-gradient-to-r from-pink-400 to-purple-500 --webkit-font-['Unbounded'] leading-10"
                    >
                        DeepfakeX
                    </a>

                    <Content>
                        <article>
                            <div
                                className="flex flex-col bg-neutral-900 justify-start items-start gap-4 px-8 xl:px-48 py-8 xl:py-16">
                                <h1 className="font-extrabold text-zinc-100 text-xl lg:text-3xl bg-clip-text --webkit-font-['Unbounded'] leading-10">Millie
                                    Bobby Brown Deepfake Example</h1>
                                <time
                                    dateTime="2024-10-30T12:00:00"
                                    className="text-center text-zinc-400 text-sm font-medium --webkit-font-['Onest'] leading-normal"
                                >
                                    October 30th, 2024, 12:00
                                </time>
                                <div
                                    className="transition ease-in-out delay-75 px-10 py-3 bg-pink-500 rounded-2xl hover:cursor-pointer hover:-translate-y-1 hover:scale-105 duration-100"
                                    onClick={() => window.open(buildAppUrl())}
                                >
                                    <div
                                        className="text-center text-zinc-100 text-sm font-bold --webkit-font-['Onest'] leading-normal"
                                    >
                                        Create Deepfake Video
                                    </div>
                                </div>
                                <div className="w-7/12">
                                    <LiteYouTubeEmbed
                                        id="pMzA94lBmjU"
                                        title="Millie Bobby Brown Deepfake Example"
                                    />
                                </div>
                                <p
                                    className="text-left text-zinc-100 text-lg font-medium --webkit-font-['Onest'] leading-normal"
                                >
                                    Imagine a scenario where Millie Bobby Brown steps into the role of a young Jedi in
                                    the Star Wars universe. With her ability to embody complex characters, she could
                                    portray a new heroine battling the forces of the Dark Side.
                                    <br/><br/>
                                    In a fan-made deep fake video, Millie could be seen wielding a lightsaber, training
                                    under the guidance of a legendary Jedi master, and forging alliances with iconic
                                    characters like Rey and Finn. The visual effects would merge her youthful charm with
                                    the epic grandeur of Star Wars, creating a mesmerizing result that fans would love.
                                    <br/><br/>
                                    Reactions from the Star Wars community would likely be mixed, as some fans embrace
                                    the creativity of deepfake technology, while others may express concern over its
                                    implications. Nevertheless, the potential for Millie's involvement in such a
                                    legendary franchise illustrates the exciting possibilities that deepfake technology
                                    offers.
                                </p>
                            </div>
                        </article>
                    </Content>
                    <FooterPanel/>
                </Layout>
            </Space>
        </div>
    );
};

export default ExampleBobbyBrownPage;