import React, {FC, MutableRefObject, useRef} from "react";
import {Layout, Space} from "antd";
import HeaderPanel from "../Components/HeaderPanel";
import HeroBlock from "../Components/HeroBlock";
import FeaturesBlock from "../Components/FeaturesBlock";
import StepsBlock from "../Components/StepsBlock";
import FeedbackBlock from "../Components/FeedbackBlock";
import TryBlock from "../Components/TryBlock";
import FooterPanel from "../Components/FooterPanel";
import {buildAppUrl} from "../Helpers/BuildAppUrl";
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';
import {ExamplePreview} from "../Components/ExamplePreview";

const { Header, Footer, Sider, Content } = Layout;

const ExampleEmmaWatsonPage: FC = () => {
    return (
        <div className="bg-neutral-900">
            <Space direction="vertical" style={{ width: '100%' }}>
                <Layout>
                    <a
                        href={"https://deepfakex.ai"}
                        className="left-[24px] top-[12px] absolute invisible md:visible font-bold text-transparent text-2xl bg-clip-text bg-gradient-to-r from-pink-400 to-purple-500 --webkit-font-['Unbounded'] leading-10"
                    >
                        DeepfakeX
                    </a>
                    <Content>
                        <article>
                            <div
                                className="flex flex-col bg-neutral-900 justify-start items-start gap-4 px-8 xl:px-48 py-8 xl:py-16">
                                <h1 className="font-extrabold text-zinc-100 text-xl lg:text-3xl bg-clip-text --webkit-font-['Unbounded'] leading-10">
                                    Emma Watson Deepfake Example</h1>
                                <time
                                    dateTime="2024-10-30T13:00:00"
                                    className="text-center text-zinc-400 text-sm font-medium --webkit-font-['Onest'] leading-normal"
                                >
                                    October 30th, 2024, 13:00
                                </time>
                                <div
                                    className="transition ease-in-out delay-75 px-10 py-3 bg-pink-500 rounded-2xl hover:cursor-pointer hover:-translate-y-1 hover:scale-105 duration-100"
                                    onClick={() => window.open(buildAppUrl())}
                                >
                                    <div
                                        className="text-center text-zinc-100 text-sm font-bold --webkit-font-['Onest'] leading-normal"
                                    >
                                        Create Deepfake Video
                                    </div>
                                </div>
                                <div className="w-7/12">
                                    <LiteYouTubeEmbed
                                        id="hmteKIeZ9is"
                                        title="Emma Watson Deepfake Example"
                                    />
                                </div>
                                <p
                                    className="text-left text-zinc-100 text-lg font-medium --webkit-font-['Onest'] leading-normal"
                                >
                                    Emma Watson, renowned for her roles in the Harry Potter series and as an advocate
                                    for
                                    gender equality, has also become a notable figure in the evolving deepfake and face
                                    swap
                                    video industry. These technologies have garnered significant attention for their
                                    ability
                                    to seamlessly blend faces and create realistic yet fictional scenes. One intriguing
                                    example involves Emma Watson being deepfaked into the
                                    popular <b>WandaVision</b> series from
                                    the Marvel Cinematic Universe.
                                    <br/><br/>
                                    In these creative renditions, Watson’s face is meticulously overlaid onto Elizabeth
                                    Olsen's portrayal of Wanda Maximoff, offering a speculative glimpse of how Watson
                                    might
                                    embody the character. Fans are often captivated by the striking visual
                                    transformations,
                                    enabling them to imagine their favorite actors in diverse roles beyond their
                                    traditional
                                    castings. Such deepfakes emphasize not only the technical prowess behind the
                                    technology
                                    but also its potential for reshaping entertainment landscapes.
                                    <br/><br/>
                                    However, the application of deepfake technology is double-edged. On one side, it
                                    showcases innovative storytelling possibilities and artistic expression, enriching
                                    fan
                                    experiences. On the flip side, it raises ethical concerns about consent and the
                                    potential misuse of someone’s likeness without their permission. Responsible use and
                                    stringent regulations are vital in navigating the ethical terrain of these digital
                                    marvels.
                                    <br/><br/>
                                    For enthusiasts in the Face Swap and Deepfake industry, such creative exercises
                                    highlight the blend of talent, technology, and the critical importance of ethical
                                    considerations. The excitement around seeing Emma Watson as Wanda Maximoff
                                    underscores
                                    the transformative impact deepfakes may have on media, enhanced by AI advancements
                                    while
                                    shaped by the need for responsible implementation.
                                </p>
                            </div>
                        </article>
                    </Content>
                    <FooterPanel/>
                </Layout>
            </Space>
        </div>
    );
};

export default ExampleEmmaWatsonPage;