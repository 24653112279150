import React, {FC, MutableRefObject, useRef} from "react";
import {Layout, Space} from "antd";
import HeaderPanel from "../Components/HeaderPanel";
import HeroBlock from "../Components/HeroBlock";
import FeaturesBlock from "../Components/FeaturesBlock";
import StepsBlock from "../Components/StepsBlock";
import FeedbackBlock from "../Components/FeedbackBlock";
import TryBlock from "../Components/TryBlock";
import FooterPanel from "../Components/FooterPanel";
import {buildAppUrl} from "../Helpers/BuildAppUrl";
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';
import {ExamplePreview} from "../Components/ExamplePreview";

const { Header, Footer, Sider, Content } = Layout;

const ExamplesPage: FC = () => {
    return (
        <div className="bg-neutral-900">
            <Space direction="vertical" style={{ width: '100%' }}>
                <Layout>
                    <a
                        href={"https://deepfakex.ai"}
                        className="left-[24px] top-[12px] absolute invisible md:visible font-bold text-transparent text-2xl bg-clip-text bg-gradient-to-r from-pink-400 to-purple-500 --webkit-font-['Unbounded'] leading-10"
                    >
                        DeepfakeX
                    </a>
                    <Content>
                        <div
                            className="bg-neutral-900 px-8 xl:px-48 py-8 xl:py-16 justify-start items-start"
                        >
                            <h1 className="font-extrabold text-zinc-100 text-3xl --webkit-font-['Unbounded']">Deepfake
                                Examples</h1>
                            <div
                                className="transition ease-in-out delay-75 px-10 py-3 bg-pink-500 rounded-2xl hover:cursor-pointer hover:-translate-y-1 hover:scale-105 duration-100"
                                onClick={() => window.open(buildAppUrl())}
                            >
                                <div
                                    className="text-zinc-100 text-sm font-bold --webkit-font-['Onest']"
                                >
                                    Create Deepfake Video
                                </div>
                            </div>
                            <div className="flex flex-wrap justify-start items-start gap-4 px-8 py-8">
                                <a
                                    href="https://deepfakex.ai/millie-bobby-brown-deepfake"
                                >
                                    <ExamplePreview title="Millie Bobby Brown Deepfake Example" videoId="pMzA94lBmjU"/>
                                </a>

                                <a
                                    href="https://deepfakex.ai/emma-watson-deepfake"
                                >
                                    <ExamplePreview title="Emma Watson Deepfake Example" videoId="hmteKIeZ9is"/>
                                </a>

                                <a
                                    href="https://deepfakex.ai/dua-lipa-deepfake"
                                >
                                    <ExamplePreview title="Dua Lipa Deepfake Example" videoId="q6Wa2drBTaY"/>
                                </a>


                                <ExamplePreview title="Nicki Minaj Deepfake Example" videoId="XTSuOX0HPKM"/>
                                <ExamplePreview title="Kylie Jenner Deepfake Example" videoId="4eRhohyGDeM"/>
                                <ExamplePreview title="Ana De Armas Example" videoId="IHtAc2jSAOs"/>
                                <ExamplePreview title="Kaley Cuoco Deepfake Example" videoId="-HBCcogDQ5w"/>
                                <ExamplePreview title="Brie Larson Deepfake Example" videoId="g3_9c7kQq7M"/>
                                <ExamplePreview title="Doja Cat Deepfake Example" videoId="g-3wJXAdpAU"/>
                                <ExamplePreview title="Alexandra Daddario Deepfake Example" videoId="5v6x3TaRf1A"/>
                            </div>
                        </div>
                    </Content>
                    <FooterPanel/>
                </Layout>
            </Space>
        </div>
    );
};

export default ExamplesPage;

/*
    <ExamplePreview title="Madison Beer Deepfake Example" videoId="pMzA94lBmjU"/>
    <ExamplePreview title="Sommer Ray Deepfake Example" videoId="pMzA94lBmjU"/>
    <ExamplePreview title="Aubrey Plaza Deepfake Example" videoId="pMzA94lBmjU"/>
    <ExamplePreview title="Brooke Monk Deepfake Example" videoId="pMzA94lBmjU"/>
    <ExamplePreview title="Milana Vayntrub Deepfake Example" videoId="pMzA94lBmjU"/>
    <ExamplePreview title="Jenna Fischer Deepfake Example" videoId="pMzA94lBmjU"/>
    <ExamplePreview title="Irene Deepfake Example" videoId="pMzA94lBmjU"/>
    <ExamplePreview title="Andrea Botez Deepfake Example" videoId="pMzA94lBmjU"/>
    <ExamplePreview title="Miranda Cosgrove Deepfake Example" videoId="pMzA94lBmjU"/>
    <ExamplePreview title="Rhea Ripley Deepfake Example" videoId="pMzA94lBmjU"/>
    <ExamplePreview title="Vanessa Hudgens Deepfake Example" videoId="pMzA94lBmjU"/>
    <ExamplePreview title="Rachel McAdams Deepfake Example" videoId="pMzA94lBmjU"/>
    <ExamplePreview title="Natalia Dyer Deepfake Example" videoId="pMzA94lBmjU"/>
 */