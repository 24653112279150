import {ConfigProvider} from "antd";
import './App.css';
import {Route, BrowserRouter, Routes} from "react-router-dom";
import LandingPage from "./Pages/LandingPage";
import React from 'react';
import TelegramPreland from "./Pages/TelegramPreland";
import ExamplesPage from "./Pages/ExamplesPage";
import ExampleBobbyBrownPage from "./Pages/ExampleBobbyBrownPage";
import ExampleEmmaWatsonPage from "./Pages/ExampleEmmaWatsonPage";
import ExampleDuaLipaPage from "./Pages/ExampleDuaLipaPage";

function App() {
  return (
      <ConfigProvider
          theme={{ token: { colorPrimary: '#C559A9' } }}
      >
        <div className="App">
          <BrowserRouter>
            <Routes>
              <Route key="landing" path="/" element={<LandingPage />}/>
              <Route key="tg-preland-ru" path="/tg-preland-ru" element={<TelegramPreland />}/>
              <Route key="examples" path="/examples" element={<ExamplesPage />}/>
              <Route key="m-b-b-example" path="/millie-bobby-brown-deepfake" element={<ExampleBobbyBrownPage />}/>
              <Route key="e-w-example" path="/emma-watson-deepfake" element={<ExampleEmmaWatsonPage />}/>
              <Route key="d-l-example" path="/dua-lipa-deepfake" element={<ExampleDuaLipaPage />}/>
            </Routes>
          </BrowserRouter>
        </div>
      </ConfigProvider>
  );
}

export default App;
