import * as amplitude from "@amplitude/analytics-browser";

export function buildAppUrl(): string {
    var urlToReturn = "https://app.deepfakex.ai?deviceId="
        + amplitude.getDeviceId()
        + "&userId="
        + amplitude.getUserId();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    // @ts-ignore
    for (const entry of urlParams.entries()) {
        urlToReturn += "&" + entry[0] + "=" + entry[1]
    }

    return urlToReturn
}

export function buildTgURL(): string {
    var urlToReturn = "https://t.me/ai_face_deepfake_bot?start=our-landing"
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    // @ts-ignore
    for (const entry of urlParams.entries()) {
        urlToReturn += "_" + entry[0] + "_" + entry[1]
    }

    return urlToReturn
}